<template>
    <div class="login-warp">
        <div class="loginBox">
            <div style="margin-top:40px" class="flex title">
                <div style="font-size:25px">
                    欢迎注册海豚寻舱
                </div>
                <div style="font-size:15px" class="cursor" @click="goLogin">
                   <i class="huse">已有账号,</i> 去登陆
                </div>
            </div>
            <div class="inputBox">
                
                    <van-field label-width="120px" label-class="label-class" v-model="form.phone" :border="border" label="手机号：" placeholder="请输入手机号" />
                    <van-field label-width="120px" style="margin-top:34px" label-class="label-class" v-model="form.phoneCode" :border="border" label="验证码：" placeholder="请输入手机号验证码" >
                        
                        <!-- <template #button>
                            <van-button size="small" type="primary">发送验证码</van-button>
                        </template> -->
                    </van-field>
                    <button size="small" type="primary" :disabled="disabled" class="yanzhengma" @click="fason">{{codeText}}</button>
                     <!-- <van-field label-width="120px" style="margin-top:34px" label-class="label-class" v-model="form.phone" :border="border" label="设置密码：" placeholder="请输入密码" />
                     <van-field label-width="120px" style="margin-top:34px" label-class="label-class" v-model="form.phone" :border="border" label="重复密码：" placeholder="请输入重复密码" /> -->
                      <van-field label-width="120px" style="margin-top:34px" label-class="label-class" v-model="form.name" :border="border" label="联系人姓名：" placeholder="请输入联系人姓名" />
                     <!-- <van-field label-width="120px" style="margin-top:34px" label-class="label-class" v-model="form.emergencyContactPhone" :border="border" label="联系方式：" placeholder="请输入联系方式" /> -->
                      <van-field label-width="120px" style="margin-top:34px" label-class="label-class" v-model="form.company" :border="border" label="公司全称：" placeholder="请输入公司全称" />
                <div class="loginBtn" style="margin-top:40px" @click="register">
                    登录
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import Md5 from 'js-md5';
export default {
    data(){
        return{
            border:false,
            form:{
                 phone: '',//电话
                 phoneCode: '',//验证码
                 emergencyContactPhone:'',
                 parentCode: '',//绑定邀请码 非必输
                 name: '',//联系人姓名
                 company: '',//公司
                 userChannel: 'cUser',//cUser //非必输
                 pwd: '',
                 emergencyContact:''
            },
            timer:null,
            disabled:false,
            codeText:'发送验证码'
        }
    },
    methods: {
        
        fason(){
            const TIME_COUNT = 60
            if(this.form.phone == ''){
                this.$toast.fail('请输入手机号');
                return false
            }
             if (!this.timer) {
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                    this.disabled = true
                    this.codeText = this.count + 'S后获取'
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.disabled = false
                    this.codeText='获取验证码';
                }
            }, 1000)
            }
            this.$post('/user/sendPhoneCode',({phone:this.form.phone})).then(res =>{
                    if(res.code == '200'){

                    }
            })
            // /user/sendPhoneCode
            // api
        },
        goLogin(){
            this.$router.push('/login')
        },
        register(){
            //  phone: '17733120905',//电话
            //      phoneCode: '',//验证码
            //      parentCode: '',//绑定邀请码 非必输
            //      name: '',//联系人姓名
            //      company: '',//公司
            //      userChannel: '',//cUser //非必输
            //      pwd: '',
            if(this.form.phone == ''){
                this.$toast.fail('请输入手机号');
                return false
            }
            if(this.form.phoneCode == ''){
                this.$toast.fail('请输入验证码');
                return false
            }
            if(this.form.name == ''){
                this.$toast.fail('请输入联系人姓名');
                return false
            }
            if(this.form.company == ''){
                this.$toast.fail('请输入公司名称');
                return false
            }
           
            // let Md5
            let params = JSON.parse(JSON.stringify(this.form))
            params.phoneCode = Md5(this.form.phoneCode)
            this.$post('/user/registerByPhone',params).then(res =>{
                if(res.code == '200'){
                 
                    localStorage.setItem('userInfo',JSON.stringify(res.data))
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("empId", res.data.userId);
                    localStorage.setItem("username", res.data.username);
                    localStorage.setItem("fillName", res.data.fillName);
                    localStorage.setItem("positionName", res.data.positionName);
                    localStorage.setItem("multistageDetp", res.data.multistageDetp);
                    this.$router.push('/index')
                }else{
                    this.$toast.fail(res.msg)
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
 .login-warp{
        width: 100vw;
        height: 100vh;
        position: relative;
        background: url("../../assets/images/loginback.png") no-repeat 100% 100% ;
        background-size: cover;
    }
.loginBox{
    float: right;
    margin-top: 94px;
    margin-right: 140px;
   width: 495px;
// height: 552px;
padding-bottom: 50px;
background: #FFFFFF;
box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.09);
border-radius: 24px;
}
.title{
    display: flex;
    justify-content: space-between;
    margin: 40px 43px 50px;
    font-size: 28px;
    color: #1677FF;
    font-weight: 500;
    align-items: center;
}
.huse{
    color: #CCCCCC;
    font-size: 18px;
}
.inputBox{
    margin: 0 43px;
    position: relative;
}
.yanzhengma{
    position: absolute;
    right: 0;
    top:50px;
    cursor: pointer;
   width: 94px;
height: 28px;
background: linear-gradient(287deg, #1677FF 0%, #639AF8 100%, #5533FF 100%);
border-radius: 4px;
color: #fff;
}
.loginBtn{
    width: 405px;
height: 61px;
background: linear-gradient(305deg, #1677FF 0%, #639AF8 100%, #5533FF 100%);
border-radius: 4px;
color: #fff;
line-height: 61px;
cursor: pointer;
text-align: center;
font-size: 20px;
// border: 1px solid rgba(32, 53, 128, 0.16);
}
::v-deep .label-class span{
    width: 130px;
    // margin-right: 30px;
    font-weight: 500;
    font-size: 20px;
    color: #333333 !important;
    line-height: 26px;
    text-align: center;
    
}
::v-deep .el-form-item__content{
    display: flex;
}
::v-deep .el-input{
    margin-left: 30px;
}
::v-deep .van-cell{
    padding-left: 0 !important;
    padding: 0 !important;
    height: 28px !important;
    padding-bottom: 10px;
}
::v-deep .van-field__body{
    border-bottom: 1px solid #ccc !important;
    padding-bottom: 3px;
}
</style>